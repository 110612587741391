import React from 'react';

export default function() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-md-2">
          <div className="alert alert-info">
            <h2>EI1061</h2>
            <h1>I2</h1>
            <h3>2013/14</h3>
          </div>
          <ul className="nav nav-pills flex-column">
            <li className="nav-item">
              <a className="nav-link active" href="#1">
                Ejercicio 1
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#2">
                Ejercicio 2
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#3">
                Ejercicio 3
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#4">
                Ejercicio 4
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#5">
                Ejercicio 5
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#6">
                Ejercicio 6
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#7">
                Ejercicio 7
              </a>
            </li>
          </ul>
        </div>
        <div className="col-xs-12 col-md-10">
          <div className="page-header">
            <h1>Entregable I2</h1>
            <h2>EI1061 &mdash; Arquitecturas Avanzadas</h2>
          </div>
          <dl>
            <dt>Apellidos y nombre:</dt>
            <dd>Gómez Alcañiz, Óscar</dd>
            <dt>Tiempo estimado:</dt>
            <dd>3h</dd>
            <dt>Tiempo empleado:</dt>
            <dd>~3h</dd>
          </dl>
          <h2>Riesgos de control</h2>
          <ol>
            <li>
              <a name="1"></a>Lee la sección 2.3.2 del capítulo 2 del libro &ldquo;Arquitectura de Computadores&rdquo;
              (&asymp;5 páginas). Identifica aquellas partes que se corresponden con los objetivos formativos de dicho
              tema (los objetivos formativos del tema 1 están en un documento propio en el Aula Virtual).
              <blockquote>
                <strong>1.5</strong> Explicar en qué consisten los riesgos de control, y sus causas, consecuencias y
                soluciones
              </blockquote>
            </li>
            <li>
              <a name="2"></a>Distingue entre evaluar la condición de salto y resolver el salto: Evaluar la condición
              supone realizar las operaciones necesarias para conocer si el salto se debe producir o no. Resolver un
              salto incluye evaluarlo, realizar las operaciones necesarias para averiguar la dirección de salto y
              escribir esa dirección en el PC.
            </li>
            <li>
              <a name="3"></a>Considera el procesador sencillo (4 etapas: <code>IF</code>, <code>ID</code>,
              <code>ALU</code>, <code>OS</code>, de un ciclo cada una), donde los riesgos de control se resuelven
              insertando instrucciones <code>nop</code> tras el salto. ¿Cuántas instrucciones <code>nop</code> sería
              necesario insertar si el salto se resuelve en la etapa <code>ALU</code>? ¿Por qué?
              <blockquote>
                Dos instrucciones <code>nop</code>, ya que no se podría saber si se da la condición de salto hasta el
                tercer ciclo de la instrucción que precede al salto. Si el salto es efectivo, el nuevo PC se calcula en
                la tercera etapa de la instrucción del salto, y para entonces ya hay dos instrucciones en el cauce de
                ejecución.
              </blockquote>
            </li>
            <li>
              <a name="4"></a>En el procesador sencillo, si el salto se resuelve en la etapa<code>ID</code>, ¿cuántas
              instrucciones<code>nop</code> sería necesario insertar y qué tipo de dato (direccionamiento) podremos usar
              para el desplazamiento respecto al PC? ¿Por qué?
              <blockquote>
                Basta una instrucción<code>nop</code>, pues en el segundo ciclo de la instrucción del salto ya se puede
                calcular el nuevo PC, usando para ello un offset sumado al PC actual, que se extrae directamente de un
                campo de la instrucción.
              </blockquote>
            </li>
            <li>
              <a name="5"></a>En el procesador sencillo, a la hora de evaluar el salto en la etapa<code>ID</code>, ¿qué
              ventaja presenta el formato de instrucción de salto<code>bz r3, despl</code>, donde el salto se produce si
              <code>r3</code> es cero, frente al formato<code>bz despl</code>, donde el salto se produce si el resultado
              de la instrucción anterior al salto fue cero? Ilustra tu respuesta mediante algún ejemplo.
              <blockquote>
                En el caso del formato<code>bz r3, despl</code>, tenemos que al poder especificar sobre qué registro se
                hace la comparación con 0, no es necesario esperar a que la etapa<code>ALU</code> de la anterior
                instrucción termine si no hay dependencias en el salto. En el otro formato la dependencia entre la
                instrucción anterior al salto y la del salto obliga a que vayan seguidas y no pueden intercalarse
                instrucciones no dependientes entre ambas.
              </blockquote>
            </li>
            <li>
              <a name="6"></a>Considera el siguiente código
              <pre></pre>
              <p>
                ¿Si r0=0, cuántos ciclos necesita el procesador sencillo para ejecutarlo si los saltos se resuelven en
                la etapa ID y se tratan mediante la parada del cauce? Asume que existen caminos de atajo en el
                procesador. ¿Cuántos ciclos necesita el procesador sencillo para ejecutarlo si los saltos se resuelven
                en la etapa ALU y se tratan mediante la técnica de salto retardado? Reorganiza el código si es posible
                para minimizar el efecto negativo del salto.
              </p>
              <div className="table-responsive">
                <table className="cycle-diagram table table-striped">
                  <caption>
                    <strong>Resolución en ID, tratamiento con parada</strong>
                  </caption>
                  <thead>
                    <tr>
                      <th>Ciclo</th>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                      <th>8</th>
                      <th>9</th>
                      <th>10</th>
                      <th>11</th>
                      <th>12</th>
                      <th>13</th>
                      <th>&hellip;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>addi r1, r0, 20</td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-success start">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="10"></td>
                    </tr>
                    <tr>
                      <td>bucle: add r2, r2, r1</td>
                      <td></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-success end">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="9"></td>
                    </tr>
                    <tr>
                      <td>multi r3, r1, 2</td>
                      <td colspan="2"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-success start">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="8"></td>
                    </tr>
                    <tr>
                      <td>add r4, r4, r3</td>
                      <td colspan="3"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-success end">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="7"></td>
                    </tr>
                    <tr>
                      <td>addi r1, r1, -1</td>
                      <td colspan="4"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-success start">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="6"></td>
                    </tr>
                    <tr>
                      <td>bnez r1, bucle</td>
                      <td colspan="5"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning opacity-50">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-success end">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="4">&larr; entra en el bucle</td>
                    </tr>
                    <tr>
                      <td>NOP</td>
                      <td colspan="7"></td>
                      <td className="opacity-50">
                        <div className="alert alert-warning">*</div>
                      </td>
                      <td className="opacity-50">
                        <div className="alert alert-warning">*</div>
                      </td>
                      <td className="opacity-50">
                        <div className="alert alert-warning">*</div>
                      </td>
                      <td className="opacity-50">
                        <div className="alert alert-warning">*</div>
                      </td>
                      <td colspan="3">&larr; ciclo de parada</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colspan="14">(se repite 20 veces más)</td>
                    </tr>
                    <tr>
                      <th colspan="2">&hellip;</th>
                      <th>136</th>
                      <th>137</th>
                      <th>138</th>
                      <th>139</th>
                      <th>140</th>
                      <th>141</th>
                      <th>142</th>
                      <th>143</th>
                      <th>144</th>
                      <th>145</th>
                      <th>146</th>
                      <th>147</th>
                      <th>148</th>
                    </tr>
                    <tr>
                      <td>add r5, r2, r4</td>
                      <td colspan="7"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="3"></td>
                    </tr>
                    <tr>
                      <td>sub r6, r2, r4</td>
                      <td colspan="8"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="2"></td>
                    </tr>
                    <tr>
                      <td>mult r7, r2, r4</td>
                      <td colspan="9"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="15">
                        <strong>Total:</strong> 4 + 20 &times; 7 + 3 = 147 ciclos
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="table-responsive">
                <table className="cycle-diagram table table-striped">
                  <caption>
                    <strong>Resolución en ALU, tratamiento con salto retardado</strong>
                  </caption>
                  <thead>
                    <tr>
                      <th>Ciclo</th>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                      <th>8</th>
                      <th>9</th>
                      <th>10</th>
                      <th>11</th>
                      <th>12</th>
                      <th>13</th>
                      <th>&hellip;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>addi r1, r0, 20</td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-success start">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="10"></td>
                    </tr>
                    <tr>
                      <td>bucle: add r2, r2, r1</td>
                      <td></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-success end">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="9"></td>
                    </tr>
                    <tr>
                      <td>multi r3, r1, 2</td>
                      <td colspan="2"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-success start">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="8"></td>
                    </tr>
                    <tr>
                      <td>addi r1, r1, -1</td>
                      <td colspan="3"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-success start">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="7"></td>
                    </tr>
                    <tr>
                      <td>bnez r1, bucle</td>
                      <td colspan="4"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-success end">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="6">&larr; entra en el bucle</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>add r4, r4, r3</strong>
                      </td>
                      <td colspan="5"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="5">&larr; reordenada para rellenar</td>
                    </tr>
                    <tr>
                      <td>NOP</td>
                      <td colspan="6"></td>
                      <td className="opacity-50">
                        <div className="alert alert-warning">*</div>
                      </td>
                      <td className="opacity-50">
                        <div className="alert alert-warning">*</div>
                      </td>
                      <td className="opacity-50">
                        <div className="alert alert-warning">*</div>
                      </td>
                      <td className="opacity-50">
                        <div className="alert alert-warning">*</div>
                      </td>
                      <td colspan="4">&larr; ciclo de parada</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colspan="14">(se repite 20 veces más)</td>
                    </tr>
                    <tr>
                      <th colspan="2">&hellip;</th>
                      <th>116</th>
                      <th>117</th>
                      <th>118</th>
                      <th>119</th>
                      <th>120</th>
                      <th>121</th>
                      <th>122</th>
                      <th>123</th>
                      <th>124</th>
                      <th>125</th>
                      <th>126</th>
                      <th>127</th>
                      <th>128</th>
                    </tr>
                    <tr>
                      <td>add r5, r2, r4</td>
                      <td colspan="7"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="3"></td>
                    </tr>
                    <tr>
                      <td>sub r6, r2, r4</td>
                      <td colspan="8"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="2"></td>
                    </tr>
                    <tr>
                      <td>mult r7, r2, r4</td>
                      <td colspan="9"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="15">
                        <strong>Total:</strong> 4 + 20 &times; 6 + 3 = 127 ciclos
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </li>
            <li>
              <a name="7"></a>Una posible solución para los saltos es la técnica de predicción no tomado en la que el
              procesador sigue ejecutanto la(s ) instrucción(es ) siguiente(s ) al salto como si éste no se tomase y,
              cuando el salto se resuelve, las aborta si éste sí debía haber sido tomado. Para el código anterior,
              ¿cuántos ciclos necesita el procesador sencillo para ejecutarlo si los saltos se resuelven en la etapa ID
              y se tratan mediante la técnica predicción no tomado? ¿y si los saltos se resuelven en la etapa ALU?
              <div className="table-responsive">
                <table className="cycle-diagram table table-striped">
                  <caption>
                    <strong>Resolución en ALU, tratamiento con predicción no tomado</strong>
                  </caption>
                  <thead>
                    <tr>
                      <th>Ciclo</th>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                      <th>8</th>
                      <th>9</th>
                      <th>10</th>
                      <th>11</th>
                      <th>12</th>
                      <th>13</th>
                      <th>&hellip;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>addi r1, r0, 20</td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-success start">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="10"></td>
                    </tr>
                    <tr>
                      <td>bucle: add r2, r2, r1</td>
                      <td></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-success end">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="9"></td>
                    </tr>
                    <tr>
                      <td>multi r3, r1, 2</td>
                      <td colspan="2"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-success start">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="8"></td>
                    </tr>
                    <tr>
                      <td>addi r1, r1, -1</td>
                      <td colspan="3"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-success start">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="7"></td>
                    </tr>
                    <tr>
                      <td>
                        <strong>add r4, r4, r3</strong>
                      </td>
                      <td colspan="4"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="6">&larr; reordenada para rellenar</td>
                    </tr>
                    <tr>
                      <td>bnez r1, bucle</td>
                      <td colspan="5"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="5">&larr; entra en el bucle</td>
                    </tr>
                    <tr>
                      <td>add r5, r2, r4</td>
                      <td colspan="6">se descarta excepto en la última iteración &rarr;</td>
                      <td>
                        <div className="alert alert-warning opacity-50">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning opacity-50">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-warning opacity-50">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning opacity-50">OS</div>
                      </td>
                      <td colspan="4"></td>
                    </tr>
                    <tr>
                      <td>sub r6, r2, r4</td>
                      <td colspan="7">&quot; idem</td>
                      <td>
                        <div className="alert alert-warning opacity-50">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning opacity-50">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-warning opacity-50">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning opacity-50">OS</div>
                      </td>
                      <td colspan="3"></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colspan="14">(se repite 20 veces más)</td>
                    </tr>
                    <tr>
                      <th colspan="2">&hellip;</th>
                      <th>116</th>
                      <th>117</th>
                      <th>118</th>
                      <th>119</th>
                      <th>120</th>
                      <th>121</th>
                      <th>122</th>
                      <th>123</th>
                      <th>124</th>
                      <th>125</th>
                      <th>126</th>
                      <th>127</th>
                      <th>128</th>
                    </tr>
                    <tr>
                      <td>mult r7, r2, r4</td>
                      <td colspan="7"></td>
                      <td>
                        <div className="alert alert-warning">IF</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ID</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">ALU</div>
                      </td>
                      <td>
                        <div className="alert alert-warning">OS</div>
                      </td>
                      <td colspan="3"></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="15">
                        <strong>Total:</strong> 4 + 20 &times; 7 + 1 = 125 ciclos
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </li>
          </ol>
          <footer>
            <small>
              <strong>Nota:</strong> Este entregable forma parte de la evaluación de la asignatura.
            </small>
          </footer>
        </div>
      </div>
    </div>
  );
}
